import { FinishNewsletterPopupProps } from '@husky-x/cc-finish-newsletter-popup/dist/types/models';

export const FinishProductDifferencesMock = {
  header: '<h2>ERFAHREN SIE WIE SICH UNSERE PRODUKTE UNTERSCHEIDEN</h2>',
  borderedImage: true,
  navigationButton: {
    link: '/produkte/',
    label: 'ALLE PRODUKTE ANSEHEN',
  },
  useCheckmark: false,
  tables: [
    {
      title: 'FINISH',
      legend:
        '<ul>\n<li><sup>*</sup>Bei stark verschmutztem Geschirr</li>\n<li><sup>**</sup>vs. Finish Power basierend auf dem Durchschnittsgewicht</li>\n<li><sup>***</sup>Inhaltsstoff: Enzym</li>\n</ul>',
      rows: [
        {
          text: 'Reinigung',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt82888abe65a3cf92/66a8bd75f24439cde173be08/clean.svg',
        },
        {
          text: 'Gland',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4068f74db0e98470/66a8bdf6b8115e2482a37a72/shine.svg',
        },
        {
          text: 'Entfernt 48h Eingetrocknetes<sup>*</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4b1a83960fae2f42/66a8bd75b8115e47cca37a6d/dried-on.svg',
        },
        {
          text: 'Fettlösekraft',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltef4110afe98d3440/66a8bdf6f24439951073be0f/tough-on-grease.svg',
        },
        {
          text: 'Kein Vorspülen',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltb35fc648c6362147/66a8bdf61097ec6bcaa86bf5/skip-the-rinse.svg',
        },
        {
          text: 'Ohne Auspacken',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt906d9e1af7cf7cf8/66a8bd7575e8676dacd73cd9/wrapper-free-tabs.svg',
        },
        {
          text: 'Schnellauflösend',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt84a3d1465f181c87/66a8bdf67da9b43d0cb481c0/fast-dissolving.svg',
        },
        {
          text: 'Weniger Chemikaliengehal<sup>**</sup>',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt0b422bd29369e3e4/66a8bdf6739577514719fa1a/less-chemical-weight.svg',
        },
        {
          text: 'Glasschutz',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blta20864530554004a/66a8bd751a892d24844a3ac8/glass-protection.svg',
        },
        {
          text: 'Verleiht mehr Frische',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt95a23ce6c566f108/66a8bd7517cd4f0b596f724a/freshness-boost.svg',
        },
        {
          text: 'Entfernt Eingebranntes effektiv',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf4e3970c7de0d7ea/66a8bd7506435dc50cddea0b/burnt-on-stains.svg',
        },
        {
          text: 'Richtiger Inhaltsstoff<sup>***</sup> zur richtigen Zeit',
          image:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt4228905f1347b8d8/66a8bd75f095557978747345/right-ingredient-right-time-1.png',
        },
      ],
      columns: [
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt5a1d9e23a9f38b42/66a8bab8f2443983a573bdfc/ultimate-plus-x2.png',
          productName: 'ULTIMATE PLUS',
          productLink: '/produkte/reiniger/ultimate-plus-spuelmaschinentabs/48-regular/',
          color: 'gold-gradient',
          values: [
            {
              value: 5,
            },
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt885b71b3dca45c5a/66a8bd76b3baaf4f6397e363/ultimate-x2.png',
          productName: 'ULTIMATE',
          productLink: '/produkte/reiniger/ultimate-spulmaschinentabs/54-regular/',
          color: 'black-gradient',
          values: [
            {
              value: 4,
            },
            {
              value: 4,
            },
            {
              value: 3,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/blt9a8d029b63113ce2/66a8bd75fb2f0f64f438eba8/quantum-x2.png',
          productName: 'QUANTUM',
          productLink: '/produkte/reiniger/quantum-spuelmaschinentabs/58-regular/',
          color: 'dark-blue-gradient',
          values: [
            {
              value: 3,
            },
            {
              value: 3,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
        {
          productImage:
            'https://eu-images.contentstack.com/v3/assets/blt639569eaa143b45b/bltf7e1f373bd749614/66a8bd753d47313511294ec4/power-x2.png',
          productName: 'POWER',
          productLink: '/produkte/reiniger/power-spuelmaschinentabs/30-regular/',
          color: 'blue-gradient',
          values: [
            {
              value: 2,
            },
            {
              value: 2,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
            {
              value: 1,
            },
          ],
        },
      ],
    },
  ],
};

export const FinishProductCardsMock = {
  cards: [
    {
      productName: 'FINISH POWER ALL IN ONE TABS X30',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt0fd732e136572bc6/679b431ec3e1cb705eda0735/3059946160139.png',
      cardStyle: 'finish-dark-blue',
      button: {
        link: '/productos/detergentes/tabletas/all-in-1-max/30-regular/',
        text: 'Descubre Finish Power All in One',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt7bbc4eeb3b7cab21/6763e2afd100501f2015c9ce/Shine.white.png',
          name: 'Limpieza y brillo diario',
        },
      ],
      mobileOrder: 2,
    },
    {
      productName: 'FINISH QUANTUM TABS',
      primary: true,
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt02dfd5122d2595f2/679b61b67589e5aa8b77ea6c/2048508b-80f2-45d4-840d-408a70f2b3ea.png',
      cardStyle: 'finish-dark-blue',
      button: {
        link: '/productos/detergentes/tabletas/quantum/15-regular/',
        text: 'Descubre Finish Quantum',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt7bbc4eeb3b7cab21/6763e2afd100501f2015c9ce/Shine.white.png',
          name: 'Limpieza profunda',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt635f4bc3ac6d7369/6763e28835d3d75576adb051/f83124f8-268b-4c7c-b747-18cef14557ac.png',
          name: 'Duro contra la grasa',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt7bbc4eeb3b7cab21/6763e2afd100501f2015c9ce/Shine.white.png',
          name: 'Rápida disolución',
        },
      ],
      mobileOrder: 1,
    },
    {
      productName: 'FINISH ABRILLANTADOR',
      image:
        'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt07e1a0cfb881ded8/679b4581e23aa3b96c5c0007/7791130002431.png',
      cardStyle: 'finish-blue',
      button: {
        link: '/productos/abrillantador/400ml-regular/',
        text: 'Descubre Finish Abrillantador',
      },
      icons: [
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt7bbc4eeb3b7cab21/6763e2afd100501f2015c9ce/Shine.white.png',
          name: 'Protege vasos y copas de vidrio',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/blt27a345014d084a02/6763e2afd100508b0615c9d0/Circle.white.png',
          name: 'Acelera el secado',
        },
        {
          src: 'https://eu-images.contentstack.com/v3/assets/blt4dde3b0988d0aae1/bltd6a2ad4c4f6af6f8/6763e2afc8ec551cb573e262/shield.white.png',
          name: 'Previene marcas de agua',
        },
      ],
      mobileOrder: 3,
    },
  ],
};

export const FinishNewsletterPopupMock: FinishNewsletterPopupProps = {
  headline: 'NICHTS MEHR VERPASSEN',
  description:
    'Melden Sie sich über das folgende Formular für den Finish Newsletter an und bleiben Sie immer auf dem neuesten Stand rund um Finish! Mit betätigen des ANMELDEN-Buttons willigen Sie ein, von RB Hygiene Home Deutschland GmbH Informationen zu aktuellen Angeboten, Aktionen und anderen Neuigkeiten zur Finish Marke per E-Mail zu erhalten.',
  image: {
    src: 'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt7512496341f5a468/66f53c689441d6ee01fe1a7d/newsletter_popup_image.png',
    mobileSrc:
      'https://eu-images.contentstack.com/v3/assets/bltf66eb2ba30842434/blt63c4941b86e10813/66f53c833097ebb254ddd0d8/newsletter_popup_image_mobile.png',
    alt: 'finish',
  },
  thankYou: {
    headline: 'VIELEN DANK FÜR IHRE ANMELDUNG FÜR DEN FINISH NEWSLETTER!',
    description: '',
    closeButton: {
      label: 'schließen',
      redirectUrl: '/',
    },
  },
  form: {
    id: '7f4311c4-00e3-48f9-a9cb-13b20ef2b28f',
    url: 'https://phx-promoplatform-api-pl-prod.promoplatform.rbcloud.io/api/v1/external/forms',
    submitErrorMessage: 'Bitte füllen Sie die erforderlichen Felder aus.',
  },
};
